import React from 'react';

import SectionHeading from './SectionHeading';
import NameTag from './NameTag';

import BrianGlassco from '../images/nice-words/brian-glassco.png';
import RyanClodfelter from '../images/nice-words/ryan-clodfelter.png';
import ChuweiLu from '../images/nice-words/chuwei-lu.png';

const NiceWords = () => (
  <div className="nice-words">
    <div className="nice-words__testimony">
      <SectionHeading>Nice words</SectionHeading>
      <p>
        "Brian's genuine pursuit of producing beautiful, usable, and useful
        software experiences is an incredible asset to our team. His natural eye
        for design separates him from others in his field. In addition to this,
        he is always able to contribute meaningful feedback that elevates the
        user and the primary objectives of the experience which in turn adds
        tremendous value to the business and its ability to produce compelling
        products."
      </p>
      <NameTag
        image={BrianGlassco}
        name="Brian Glassco"
        title="Director of Digital Marketing, Barebones Living"
      />
    </div>

    <div className="nice-words__testimony">
      <p>
        "Brian is someone I would always want on my team. He and I have worked
        together at different times for a total of about 6 years. As his manager
        throughout most of that time, I couldn’t have asked for a better team
        member. He has a remarkable work ethic, gets the job done, strives for
        excellence and yet is very personable and easy to work with. He excels
        at implementing beautiful user experiences and most recently worked as a
        manager on my team; his direct reports loved him! He will truly be an
        inspiration to any team he joins both in terms of the positive attitude
        he brings, and the level of technical proficiency he offers."
      </p>
      <NameTag
        image={RyanClodfelter}
        name="Ryan Clodfelter"
        title="Director of Engineering, Subsplash®"
      />
    </div>

    <div className="nice-words__testimony">
      <p>
        "I have the pleasure of having Brian as my manager for the past nine
        months. He is an effective leader who always has a clear vision for the
        team and takes prompt actions to get his team closer to the goals. He is
        a caring mentor who takes an active interests in investing in his dev
        team, always available to help his developers unblock technical or
        non-technical issues. He is also the main contributor to the frontend
        projects which have been loved by the majority of the users. Brian is a
        true asset for any team and I would love to work with him again."
      </p>
      <NameTag
        image={ChuweiLu}
        name="Chuwei Lu"
        title="Front-end engineer, Subsplash®"
      />
    </div>
  </div>
);

export default NiceWords;

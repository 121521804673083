import React from 'react';

import Layout from '../components/Layout';
import SEO from '../components/seo';

import NiceWords from '../components/NiceWords';

const WorkPage = ({ location }) => (
  <Layout currentPath={location.pathname} scroll>
    <SEO
      title="Brian Kulp Co. | Nice words"
      keywords={[`brian kulp`, `seattle`, `designer`, `developer`]}
    />
    <NiceWords />
  </Layout>
);

export default WorkPage;

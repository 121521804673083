import React from 'react';

const wrapStyles = {
  display: 'flex',
  alignItems: 'center'
};

const imageStyles = {
  borderRadius: 16,
  boxShadow: '0 2px 12px rgba(0, 0, 0, 0.22)',
  height: 32,
  marginRight: 18,
  width: 32
};

const nameStyles = {
  fontSize: 16,
  fontWeight: 600,
  lineHeight: 1.375,
  marginBottom: 4
};

const titleStyles = {
  color: '#656665',
  lineHeight: 1.45
};

const NameTag = ({ image, name, title }) => (
  <div style={wrapStyles}>
    <img style={imageStyles} src={image} alt={name} />
    <div>
      <h5 style={nameStyles}>{name}</h5>
      <h6 style={titleStyles}>{title}</h6>
    </div>
  </div>
);

export default NameTag;
